import React, { useState } from "react"
import grid from "../../scss/flexboxgrid.module.scss"
import view from "./paragraph.module.scss"
import { Link } from "gatsby";

function Paragraph({ data }) {
  const { title, paragraphText } = data
  const { bgColor, titleColor, txtColor } = data

 // const ComponentID = primaryBtnText.replace(/\s+/g, '-').toLowerCase();

  const renderParagraph = () => {
    return (
      <>
        <h1 className={view["paragraph-title"]} style={{color: titleColor }}>
          {title}
        </h1>
        
        <div className={view["paragraph-text"]} style={{color: txtColor }} dangerouslySetInnerHTML={{ __html: paragraphText }}></div>

      </>
    )
  }
  return (
    <>
      <div id='paragraph' className={view["img-para-wrap"]} style={{backgroundColor: bgColor }}>
          <div className={view["p-container"]}>{renderParagraph()}</div>
      </div>
    </>
  )
}

export default Paragraph
