import React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import Geocode from "react-geocode"
import view from './bottomMap.module.scss'

export class BottomMap extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            dealerAddress: this.props.data.dealerAddress,
            lat: 0,
            long: 0,
            width: window.innerWidth,
            markers: [],
            style: [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#c9c9c9"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                }
            ]
        }
    }

    componentDidMount() {
        Geocode.setApiKey("AIzaSyCddq6ehnmTvcAdFOvG5xEGhn8jZV3eBqY")
        Geocode.fromAddress(this.state.dealerAddress) // fetchs lat and long for google maps api from address
            .then(res => {
                const { lat, lng } = res.results[0].geometry.location;

                let array = [...this.state.markers]
                array.push({ lat: lat, lng: lng })

                this.setState({
                    lat: lat,
                    long: lng,
                    markers: array
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    mapLoaded(map) {
        map.setOptions({
            styles: this.state.style // setting custom map styling
        })
    }

    render() {
        if (this.state.lat === 0) return (<div></div>)

        let markers = this.state.markers.map((dealer, i) => {
            return (
                <Marker key={i} position={{ lat: dealer.lat, lng: dealer.lng }} title={dealer.name} name={dealer.name} />
            )
        })

        return (
            <div className={view["contact-mapCont"]}>
                <Map google={this.props.google} zoom={14} initialCenter={{ lat: this.state.lat, lng: this.state.long }} style={{ height: 480 }} containerStyle={{ height: 480 }} onReady={(mapOptions, map) => this.mapLoaded(map, this.props.google.maps)} mapTypeControl={false} streetViewControl={false} zoomControl={false} fullscreenControl={false}>
                    {markers}
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyCddq6ehnmTvcAdFOvG5xEGhn8jZV3eBqY") // Google Maps API Key
})(BottomMap)