import React, { useState } from "react"
import grid from "../../scss/flexboxgrid.module.scss"
import { Link } from "gatsby";
import { Hidden } from "@material-ui/core";


function BannerImage({data}) {
const {imgSrc, imgHeight} = data

    return(
        <div className="large-page-img" style={{height: imgHeight, overflow: "hidden"}}>
            <img src={imgSrc} style={{height: imgHeight, objectFit:"cover"}}/>
        </div>
    )

}
export default BannerImage